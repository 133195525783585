@font-face {
  font-family: 'Roboto';
  src: local('Roboto Regular'), local('Roboto-Regular'), 
       url('assets/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Medium'), local('Roboto-Medium'), 
       url('assets/fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Bold'), local('Roboto-Bold'), 
       url('assets/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
}

/* Allgemeine Stile */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Code-Stile */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Text-Stile */
.text-center {
  text-align: center;
}

.font-weight-bold {
  font-weight: bold;
}

.text-red {
  color: red;
}

.text-yellow {
  color: yellow;
}

/* LED-Stile */
.led {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.led-red {
  background-color: red;
  box-shadow: 0 0 5px 2px rgba(255, 0, 0, 0.5); 
}

.led-yellow {
  background-color:  orange;
  box-shadow: 0 0 5px 2px rgba(255, 165, 0, 0.5);
}

.led-green {
  background-color: green;
  box-shadow: 0 0 5px 2px rgba(0, 255, 0, 0.5);
}

/* Lade-Overlay */
.loading-overlay {
  z-index: 1050;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.loading {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 2px solid rgba(217, 0, 0, 0.2);
  border-radius: 50%;
  border-top-color: rgba(217, 0, 0, 1);
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  left: calc(50% - 15px);
  top: calc(50% - 15px);
  position: fixed;
  z-index: 1;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

/* Tabellen */
@media (min-width: 768px) {
  .hide-on-small {
    display: table-cell;
  }
}

@media (max-width: 767px) {
  .hide-on-small {
    display: none; 
  }
}

/* Hauptcontainer */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: 'Roboto', sans-serif;
  justify-content: space-between;
}

/* Header */
.header {
  height: 15rem;
  border: 5px solid red;
}

.header-container {
  padding: 2em;
  background-color: #f0f0f0; 
}

.logo {
  width: 7rem; 
  height: auto;
}

.header-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px; 
  text-align: center;
}

.header-title {
  font-family: 'Roboto', sans-serif;
  font-size: 42px;
  margin-bottom: 5px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.header-subtitle {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 300;
}

@media (max-width: 768px) {

  .header-text {
    margin-left: 0; 
    text-align: center; 
    align-items: center; 
  }

  .logo {
    display: block;
    margin: 0 auto; 
    width: 5rem;
    margin-bottom: .25rem;
  }

  .header-title {
    font-size: 21px;
  }

  .header-subtitle {
    font-size: 10px;
  }
}


/* Inhaltsbereich */
.content {
  padding: 20px;
  flex: 1;
}

/* Fußzeile */
.footer {
  background-color: #333;
  color: white;
  padding: 10px;
  text-align: center;

}

@media screen and (max-width: 768px) {
  .footer {
    position: relative;
    margin-top: 2rem;
  }
  
}

/* LoginForm */

.loginForm {
  margin-top: 5rem;
  flex: 1;
}

@media screen and (max-width: 768px) {
  .loginForm {
    margin-top: 2rem;
  
  }
  
}

